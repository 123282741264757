import { Box, Grid, IconButton } from "@material-ui/core";
import { useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import "./Assets/css/App.css";
import { number2text } from "./Helpers/Words";
import Sidebar from "./Shared/Sidebar/Sidebar";
import Template from "./Shared/Template/Template";

function App() {
    const [state, setState] = useState({
        date: `${new Date().getFullYear()}-0${new Date().getMonth()}-0${new Date().getDate()}`,
        billNumber: "",
        subject: "",
        billTo: "",
    });

    const [dynamic, setDynamic] = useState([
        {
            sr: 1,
            perticular: "",
            amount: "",
        },
    ]);

    const changeHandler = (evt) => {
        setState({ ...state, [evt.target.name]: evt.target.value });
    };

    const getTotal = () => {
        let total = 0;
        for (let index = 0; index < dynamic.length; index++) {
            const element = dynamic[index];
            total = Number(total) + Number(element.amount);
        }
        return total;
    };

    return (
        <div className="App">
            {/* <Sidebar /> */}
            <div className="outlet">
                <div className="outlet-wrapper">
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <span className="invoice-title" style={{ fontSize: 24 }}>
                                Generate Invoice
                            </span>
                        </Grid>
                        <Grid container style={{ marginLeft: 6, marginTop: 24 }}>
                            <Grid item xs={12} md={4}>
                                <span className="small-dividers">Your Detail:</span>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <Box className="input-field-wrapper">
                                    <div className="input-field-invoice">
                                        <span>Date</span>
                                        <input onChange={changeHandler} type="date" name="date" value={state.date} />
                                    </div>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className="input-field-wrapper">
                                    <div className="input-field-invoice">
                                        <span>Bill Number</span>
                                        <input type="text" name="billNumber" value={state.billNumber} onChange={changeHandler} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <div className="input-field-wrapper">
                                    <div className="input-field-invoice">
                                        <span>Subject</span>
                                        <input type="text" name="subject" value={state.subject} onChange={changeHandler} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginLeft: 6 }}>
                            <Grid item xs={12} md={4}>
                                <span className="small-dividers">To:</span>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <div className="input-field-wrapper">
                                    <div className="input-field-invoice">
                                        <span>Name</span>
                                        <input type="text" name="billTo" value={state.billTo} onChange={changeHandler} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container style={{ marginLeft: 6 }}>
                            <Grid item xs={12} md={4}>
                                <span className="small-dividers">Item List:</span>
                            </Grid>
                        </Grid>
                        {dynamic.map((row, idx) => (
                            <Grid container>
                                <Grid item xs={12} md={2}>
                                    <div className="input-field-wrapper">
                                        <div className="input-field-invoice">
                                            <span>Sr</span>
                                            <input
                                                type="number"
                                                value={row.sr}
                                                onChange={(evt) => {
                                                    dynamic[idx].sr = evt.target.value;
                                                    setDynamic([...dynamic]);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="input-field-wrapper">
                                        <div className="input-field-invoice">
                                            <span>Description</span>
                                            <input
                                                type="text"
                                                value={row.perticular}
                                                onChange={(evt) => {
                                                    dynamic[idx].perticular = evt.target.value;
                                                    setDynamic([...dynamic]);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <div className="input-field-wrapper">
                                        <div className="input-field-invoice">
                                            <span>Amount</span>
                                            <input
                                                type="number"
                                                value={row.amount}
                                                onChange={(evt) => {
                                                    dynamic[idx].amount = evt.target.value;
                                                    setDynamic([...dynamic]);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container style={{ marginLeft: 6 }}>
                            <IconButton
                                style={{ color: "#0039c5" }}
                                onClick={() =>
                                    setDynamic([
                                        ...dynamic,
                                        {
                                            sr: dynamic.length + 1,
                                            perticular: "",
                                            amount: "",
                                        },
                                    ])
                                }
                            >
                                <FiPlusCircle />
                            </IconButton>
                        </Grid>
                        <Grid container style={{ marginLeft: 6 }}>
                            <button
                                className="generate-btn"
                                onClick={() => {
                                    setState({ ...state, generate: true });
                                }}
                            >
                                Generate
                            </button>
                        </Grid>
                        <Grid container style={{ marginTop: 24, height: 500 }}>
                            <Grid item xs={12} md={12}>
                                {state.generate && (
                                    <Template data={state} dynamic={dynamic} amountInWords={number2text(getTotal(dynamic))} total={getTotal(dynamic)} />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export default App;
