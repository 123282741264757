import React from "react";
import { Page, Document, Image, StyleSheet, View, Text, Font } from "@react-pdf/renderer";

Font.register({
    family: "PR",
    src: require("../fonts/PR.ttf").default,
});
Font.register({
    family: "PB",
    src: require("../fonts/PB.ttf").default,
});

const styles = StyleSheet.create({
    row: {
        width: "100%",
        height: 120,
        display: "flex",
        marginLeft: "auto",
        alignItems: "center",
    },
    row2: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    reportTitle: {
        fontFamily: "PB",
        fontSize: 24,
    },
    reportTitle2: {
        fontFamily: "PB",
        fontSize: 12,
        marginRight: 6,
    },
    reportText: {
        fontFamily: "PR",
        fontSize: 12,
    },
    table: {
        display: "table",
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        marginTop: 12,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        width: "100%",
        flexDirection: "row",
    },
    tableCol1: {
        width: "10%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol2: {
        width: "60%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol3: {
        width: "30%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: "auto 0 auto 6px",
        marginTop: 5,
        fontSize: 10,
        fontFamily: "PB",
    },
    tableCellLight: {
        margin: "auto 0 auto 6px",
        marginTop: 5,
        fontSize: 10,
        fontFamily: "PR",
    },
});

const Invoice = ({ data, rows, total, amountInWords }) => {
    return (
        <Document>
            <Page size="A4" style={{ paddingHorizontal: 48, paddingVertical: 24 }}>
                <View style={styles.row} fixed>
                    <Image style={{ marginLeft: "auto", height: 120 }} src={require("../../Assets/img/logo.png").default} />
                </View>
                <View style={{ ...styles.row2, marginTop: 28 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                        <Text style={styles.reportTitle2}>INVOICE NO: </Text>
                        <Text style={styles.reportText}>{data.billNumber}</Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", marginLeft: "auto" }}>
                        <Text style={styles.reportTitle2}>Date: </Text>
                        <Text style={styles.reportText}> {data.date}</Text>
                    </View>
                </View>
                <View style={{ ...styles.row2, marginTop: 6 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                        <Text style={styles.reportTitle2}>TO. </Text>
                        <Text style={styles.reportText}>{data.billTo}</Text>
                    </View>
                </View>
                <View style={{ ...styles.row2, marginTop: 6 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                        <Text style={styles.reportTitle2}>SUBJECT: </Text>
                        <Text style={styles.reportText}>{data.subject}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol1}>
                            <Text style={styles.tableCell}>SR.NO.</Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell}>DESCRIPTION</Text>
                        </View>
                        <View style={styles.tableCol3}>
                            <Text style={styles.tableCell}>AMOUNT</Text>
                        </View>
                    </View>
                    {rows.map((row, idx) => (
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol1}>
                                <Text style={styles.tableCellLight}>{idx + 1}</Text>
                            </View>
                            <View style={styles.tableCol2}>
                                <Text style={styles.tableCellLight}>{row.perticular}</Text>
                            </View>
                            <View style={styles.tableCol3}>
                                <Text style={styles.tableCellLight}>{row.amount}</Text>
                            </View>
                        </View>
                    ))}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol1}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={{ ...styles.tableCell, marginLeft: "auto" }}>TOTAL</Text>
                        </View>
                        <View style={styles.tableCol3}>
                            <Text style={styles.tableCellLight}>{total}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ ...styles.row2, marginTop: 12 }}>
                    <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                        <Text style={styles.reportTitle2}>AMOUNT IN WORDS: </Text>
                        <Text style={{ fontFamily: "PR", fontSize: 12 }}>{amountInWords}</Text>
                    </View>
                </View>
                {/* <View style={{ ...styles.row2, marginTop: 12 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                        <Text style={styles.reportTitle2}>GST NO: </Text>
                    </View>
                </View> */}
                <View style={{ ...styles.row2, marginTop: 3 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                        <Text style={styles.reportTitle2}>BANK DETAILS:</Text>
                        <Text style={{ fontFamily: "PR", fontSize: 12 }}>50200040137981</Text>
                    </View>
                </View>
                <View style={{ ...styles.row2, marginTop: 3 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                        <Text style={styles.reportTitle2}>IFSC : </Text>
                        <Text style={{ fontFamily: "PR", fontSize: 12 }}>HDFC0001450</Text>
                    </View>
                </View>
                <View style={{ ...styles.row2, marginTop: 12 }}>
                    <View style={{ display: "flex", flexDirection: "column", marginLeft: "auto", alignItems: "center" }}>
                        <Text style={styles.reportTitle2}>YOUR FAITHFULLY,</Text>
                        <Text style={styles.reportText}>M.D.ASSOCIATE</Text>
                        <Text style={{ ...styles.reportText, fontSize: 8 }}>AR. MAHENDRA SUTHAR</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default Invoice;
