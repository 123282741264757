import React, { Component, Fragment } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import Invoice from "./Invoice";

class Template extends Component {
    constructor(props) {
        super(props);
        this.data = props.data;
        this.dynamic = props.dynamic;
        this.total = props.total;
        this.amountInWords = props.amountInWords;
    }

    render() {
        return (
            <Fragment>
                <PDFViewer className="app" style={{ width: "100%", height: 500 }}>
                    <Invoice data={this.data} rows={this.dynamic} amountInWords={this.amountInWords} total={this.total} />
                </PDFViewer>
            </Fragment>
        );
    }
}

export default Template;
