import { HiOutlineHome, HiOutlineArchive, HiOutlineCog } from "react-icons/hi";
import { DASHBOARDROUTES } from "./Routes";

const sidebarConfig = [
    {
        name: "Dashboard",
        icon: HiOutlineHome,
        path: DASHBOARDROUTES.dashboard,
    },
    {
        name: "Invoices",
        icon: HiOutlineArchive,
        path: DASHBOARDROUTES.invoices,
    },
    {
        name: "Setting",
        icon: HiOutlineCog,
        path: DASHBOARDROUTES.setting,
    },
];

export default sidebarConfig;
